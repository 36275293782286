var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-1",attrs:{"type":""}},[_c('bread-bag')],1),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-4"}},[_c('div',{staticClass:"d-flex align-items-center mb-4"},[_c('div',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(_vm.$t("contract.show_data_for"))+" ")]),_c('FormulateInput',{staticClass:"mb-0",attrs:{"type":"select","options":_vm.availableYears,"value":_vm.year},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),(_vm.loading)?_c('v-loading',{attrs:{"loading":_vm.loading}}):[_c('div',{staticClass:"mb-4 d-flex align-items-center"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("budget_report.header_for_YEAR", { YEAR: _vm.year }))+" ")]),_c('a',{staticClass:"btn btn-primary btn-sm ml-auto",attrs:{"href":("/v1/reports/budget/" + _vm.year + "?csv=1")}},[_c('i',{staticClass:"far fa-file-csv"}),_vm._v(" "+_vm._s(_vm.$t("budget_report.download_as_csv")))])]),_c('v-table',{staticClass:"table-special"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_vm._v(_vm._s(_vm.$t("budget_report.hdr_name")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("budget_report.hdr_ecnr")))]),_vm._l((12),function(i){return _c('v-th',{key:i,staticClass:"text-right"},[_vm._v(_vm._s((i + "." + _vm.year)))])})],2)],1),_c('v-tbody',_vm._l((_vm.detailed),function(d,idx){return _c('v-tr',{key:idx},_vm._l((d),function(k,kix){return _c('v-td',{key:kix},[(
                                                kix === 'name' ||
                                                    kix === 'ecnr'
                                            )?[_vm._v(" "+_vm._s(k)+" ")]:[_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$n(k, { style: "currency", currency: "DKK", currencyDisplay: "code" }))+" ")])]],2)}),1)}),1)],1),_c('hr'),_c('div',{staticClass:"mb-4 d-flex align-items-center"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("budget_report.invoicing_for_YEAR", { YEAR: _vm.year }))+" ")]),_c('a',{staticClass:"btn btn-primary btn-sm ml-auto",attrs:{"href":("/v1/reports/budget/" + _vm.year + "?csv=2")}},[_c('i',{staticClass:"far fa-file-csv"}),_vm._v(" "+_vm._s(_vm.$t("budget_report.download_as_csv")))])]),_c('v-table',{staticClass:"table-special"},[_c('v-tbody',_vm._l((_vm.budget),function(b,idx){return _c('v-tr',{key:idx},[_c('v-td',[_vm._v(_vm._s(b.entry.name))]),_c('v-td',[_vm._v(_vm._s(b.entry.ecnr))]),_vm._l((12),function(mon){return _c('v-td',{staticClass:"text-right"},[(
                                                (_vm.year + "-" + (mon
                                                    .toString()
                                                    .padStart(2, '0'))) in b
                                            )?[_vm._v(_vm._s(_vm.$n( b[ (_vm.year + "-" + (mon .toString() .padStart( 2, "0" ))) ], { style: "currency", currency: "DKK", currencyDisplay: "code" } )))]:[_vm._v("0")]],2)})],2)}),1)],1)]],2)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }