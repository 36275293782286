<script>
import dayjs from "dayjs";
import ReportService from "@/services/ReportService";

export default {
    data() {
        return {
            loading: true,
            budget: {},
            detailed: {},
            year: 0,
            availableYears: [2024, 2025]
        };
    },

    computed: {
        currentYear() {
            return dayjs().format("YYYY");
        }
    },
    watch: {
        year: function(nVal, oVal) {
            this.fetchData();
        }
    },
    async created() {
        this.year = this.currentYear;
        this.fetchData();
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                const { data } = await ReportService.GET_budget(this.year);
                this.budget = data.data.budget;
                this.detailed = data.data.detailed;
            } catch (e) {}
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-4">
                        <!-- V-FILTERS -->
                        <div class="d-flex align-items-center mb-4">
                            <div class="mr-3">
                                {{ $t("contract.show_data_for") }}
                            </div>
                            <FormulateInput
                                class="mb-0"
                                type="select"
                                :options="availableYears"
                                v-model="year"
                                :value="year"
                            />
                        </div>
                        <v-loading :loading="loading" v-if="loading" />
                        <template v-else>
                            <div class="mb-4 d-flex align-items-center">
                                <h3>
                                    {{
                                        $t("budget_report.header_for_YEAR", {
                                            YEAR: year
                                        })
                                    }}
                                </h3>

                                <a
                                    class="btn btn-primary btn-sm ml-auto"
                                    :href="`/v1/reports/budget/${year}?csv=1`"
                                >
                                    <i class="far fa-file-csv"></i>
                                    {{ $t("budget_report.download_as_csv") }}</a
                                >
                            </div>

                            <v-table class="table-special">
                                <v-thead>
                                    <v-tr>
                                        <v-th>{{
                                            $t("budget_report.hdr_name")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("budget_report.hdr_ecnr")
                                        }}</v-th>
                                        <v-th
                                            class="text-right"
                                            v-for="i in 12"
                                            :key="i"
                                            >{{ `${i}.${year}` }}</v-th
                                        >
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr
                                        v-for="(d, idx) in detailed"
                                        :key="idx"
                                    >
                                        <v-td v-for="(k, kix) in d" :key="kix">
                                            <template
                                                v-if="
                                                    kix === 'name' ||
                                                        kix === 'ecnr'
                                                "
                                            >
                                                {{ k }}
                                            </template>
                                            <template v-else>
                                                <div class="text-right">
                                                    {{
                                                        $n(k, {
                                                            style: "currency",
                                                            currency: "DKK",
                                                            currencyDisplay:
                                                                "code"
                                                        })
                                                    }}
                                                </div>
                                            </template>
                                        </v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>

                            <hr />

                            <div class="mb-4 d-flex align-items-center">
                                <h3>
                                    {{
                                        $t("budget_report.invoicing_for_YEAR", {
                                            YEAR: year
                                        })
                                    }}
                                </h3>

                                <a
                                    class="btn btn-primary btn-sm ml-auto"
                                    :href="`/v1/reports/budget/${year}?csv=2`"
                                >
                                    <i class="far fa-file-csv"></i>
                                    {{ $t("budget_report.download_as_csv") }}</a
                                >
                            </div>

                            <v-table class="table-special">
                                <v-tbody>
                                    <v-tr v-for="(b, idx) in budget" :key="idx">
                                        <v-td>{{ b.entry.name }}</v-td>
                                        <v-td>{{ b.entry.ecnr }}</v-td>
                                        <v-td
                                            v-for="mon in 12"
                                            class="text-right"
                                        >
                                            <template
                                                v-if="
                                                    `${year}-${mon
                                                        .toString()
                                                        .padStart(2, '0')}` in b
                                                "
                                                >{{
                                                    $n(
                                                        b[
                                                            `${year}-${mon
                                                                .toString()
                                                                .padStart(
                                                                    2,
                                                                    "0"
                                                                )}`
                                                        ],
                                                        {
                                                            style: "currency",
                                                            currency: "DKK",
                                                            currencyDisplay:
                                                                "code"
                                                        }
                                                    )
                                                }}</template
                                            >
                                            <template v-else>0</template>
                                        </v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.table-special {
    width: 100%;
    font-size: 10px;

    th {
        border: 1px solid $gray-500;
        padding: 5px;
        background: $gray-100;
    }

    td {
        border: 1px solid $gray-500;
        padding: 5px;
    }
}
</style>
